import axios from 'axios';

const url = 'http://hawk.mini.io.vn/'
export async function PostReq(channel,body){
    let _url=url+channel
    const headers = {
        'Content-Type': 'application/json', 
        'jwt': localStorage.getItem('jwt') 
      };
      try {
        const response = await axios.post(_url, body, { headers: headers });
        return response.data;
      } catch (error) {
        console.error('Lỗi khi thực hiện yêu cầu POST:', error);
        throw error;
      }
}


//https://thumbnails.roblox.com/v1/users/avatar-headshot?userIds=4131282278&size=420x420&format=Png&isCircular=false