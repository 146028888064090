
import { FormControl, FormLabel, RadioGroup, HStack, Radio, FormHelperText, Input, Button, Code } from "@chakra-ui/react"
import React, { useState } from "react"

export function Gift() {
    return(<>
    <Code>
        Mail: admin@gmail.com
        Pass: Enish@123
    </Code>
  <hr></hr>
    <Button colorScheme='teal' onClick={()=>{
         window.location.href = 'https://hawk.ohgame.io.vn/';
    }}>
                     Go to Service Gift
               </Button>
    </>
            
    )
    // const [type, setType] = useState('coin')
    // const [id, setId] = useState(1)
    // const [amount, setAmount] = useState(1)
    // const [msg, setMsg] = useState("WELCOME")
    // const [day, setDay] = useState(0)
    

    // return (

    //     <FormControl as='fieldset'>
    //         <FormLabel as='legend'>
    //             Type Gift
    //         </FormLabel>
    //         <RadioGroup defaultValue={type}>
    //             <HStack spacing='24px'>
    //                 <Radio value='coin'>Coin</Radio>
    //                 <Radio value='diamond'>Diamond</Radio>
    //                 <Radio value='medal'>Medal</Radio>
    //                 <Radio value='treasure'>Treasure</Radio>
    //                 <Radio value='character'>Character</Radio>
    //                 <Radio value='exp'>EXP</Radio>

    //             </HStack>
    //         </RadioGroup>
    //         <FormHelperText>
    //             If Type does not have an ID such as (Coin, Exp, Medal, ...) then please put 1 in the ID box
    //         </FormHelperText>
    //         <TextInput label='ID Gift' id='id' defaultValue={1} onChange={(event)=>{setId(event.target.value)}} />
    //         <TextInput label='Amount' id='amount' defaultValue={1} onChange={(event)=>{setAmount(event.target.value)}} />
    //         <TextInput label='Message' id='message' defaultValue={"GIFT"} onChange={(event)=>{setMsg(event.target.value)}} />
    //       <b>Expires on</b>
    //         <Input
    //             id="expire"
    //             placeholder="Expires on"
    //             size="md"
    //             type="datetime-local"
    //             onChange={(event)=>{
    //                 let data=Date.parse(event.target.value) / 1000
    //                 console.log(data)
    //                 setDay(data)}}
    //         />
    //          <Button colorScheme='teal'>
    //                Send
    //             </Button>
    //     </FormControl>
    
}