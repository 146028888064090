import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Robux from './pages/robux';
import Playing from './pages/playing';
import {
  Button,
} from '@chakra-ui/react'
import { Gift } from "./pages/gift";
function App() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState('Robux');

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    if (!jwt) {
      navigate("/login");
    }
  }, [navigate]); // Added navigate to dependency array

  const handleButtonClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="App">
      {localStorage.getItem('jwt') ? (
        <>
         <Button colorScheme='red' onClick={() => navigate('/logout')} variant='solid'>
                Logout
            </Button>
          <div>
          <Button colorScheme='teal' variant={currentPage==="Gift"?"solid":"outline"} onClick={() => handleButtonClick('Gift')}>Public gift </Button>
            <Button colorScheme='teal' variant={currentPage==="Robux"?"solid":"outline"} onClick={() => handleButtonClick('Robux')}>Log</Button>
            <Button colorScheme='teal' variant={currentPage==="Playing"?"solid":"outline"} onClick={() => handleButtonClick('Playing')}>Player Online</Button>
          </div>
          {currentPage === 'Robux' && <Robux />}
          {currentPage === 'Gift' && <Gift/>}
          {currentPage === 'Playing' && <Playing />}
        </>
      ) : (
        <div>Please log in</div>
      )}
    </div>
  );
}

export default App;
