import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { Routes, Route, Router, BrowserRouter } from 'react-router-dom';
import Login from './pages/login';
import Logout from './pages/logout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
     
        <BrowserRouter>
          <Routes>
            <Route path="" element={<App />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            {/* <Route path="/json/api/:_id" element={<JsonPage />} /> */}
            {/* <Route path='/dashboard' element={<Dashboard />}/> */}
          
          </Routes>
        </BrowserRouter>
     
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
