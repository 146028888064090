import {
    Skeleton,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Badge,
    Input
} from '@chakra-ui/react'
import axios from 'axios';

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { PostReq } from "../lib/request";
let isGet = true

function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}
function JsonToPopover(json) {
    json = JSON.parse(json)
    const convertJsonToHtml = (json) => {
        const items = [];
        for (const key in json) {

            if (json.hasOwnProperty(key)) {
                items.push(
                    <PopoverBody key={key}>
                        <b>{key}:</b> {json[key]}
                    </PopoverBody>
                );
            }
        }
        return items;
    }

    return (
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            {convertJsonToHtml(json)}
        </PopoverContent>
    );
}
async function getAvatar(userId) {

    try {
        let apiThump = await axios.get("http://thumbnails.roproxy.com/v1/users/avatar-headshot?userIds=" + userId + "&size=420x420&format=Png&isCircular=false")

        return apiThump.data.data[0].imageUrl
    } catch (e) {
        console.log(e)
        return null
    }



}
function Robux() {
    const [page, setPage] = useState(1);
    const Navigate = useNavigate();
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                let data = await PostReq("robux/all", { page: page })
                const promises = data.map(async (item) => {
                    item.avatar = await getAvatar(item.userid);
                    return item;
                });
                data = await Promise.all(promises);
                setData(data)
            } catch (error) {

            }
        };
        fetchData();
    }, [page])
    return (
        <div >

            <TableContainer>
            <Button onClick={() => {
                    if(page - 1<=0){
                        setPage(1)
                    }else{
                        setPage(page - 1)
                    }
                   
                }}>{'<< Previous Page'}</Button>
                <Input w={'100px'} type={"number"} color={"red"} value={page} disabled></Input>
                <Button onClick={() => {
                    setPage(page + 1)
                }}>{'>> Next Page'}</Button> 
                 <Button onClick={() => {
                    setPage(page)
                }}>{'Refresh'}</Button> 
                
                <Table>
                    <TableCaption>Roblox Log Robux</TableCaption>

                    <Thead>
                        <Tr>
                            <Th>Version</Th>
                            <Th>Name</Th>
                            <Th>Content</Th>
                            <Th>Data</Th>
                            <Th>Created At</Th>

                        </Tr>
                    </Thead>

                    <Tbody>

                        {data.map((item, index) => (

                            <Tr key={index}>


                                <Td>{item.isServer == 1 ? <Badge colorScheme='green'>Experience</Badge> : <Badge colorScheme='red'>Studio</Badge>}</Td>
                                <Td><Popover>
                                    <PopoverTrigger>
                                        <Button>{item.name}</Button>
                                    </PopoverTrigger>
                                    <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <PopoverBody><b>gameId:</b> {item.placeId}</PopoverBody>
                                        <PopoverBody><b>UserId:</b> {item.userid}</PopoverBody>
                                        <PopoverBody><b>Name:</b> {item.name}</PopoverBody>
                                        <PopoverBody> {item.avatar && <img src={item.avatar} width={"100px"} alt={`Avatar of ${item.name}`} />}</PopoverBody>
                                    </PopoverContent>
                                </Popover></Td>
                                <Td>{item.content}</Td>
                                <Td><Popover>
                                    <PopoverTrigger>
                                        <Button>Bill Data</Button>
                                    </PopoverTrigger>


                                    {JsonToPopover(item.data)}

                                </Popover></Td>
                                <Td>{formatDate(item.createdAt)}</Td>

                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default Robux;
