import './css/login.css'
import React, { useState } from 'react';
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function Login() {
    const Navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const toast = useToast()
    const handleLogin = async (event) => {
        setShowPopup(true)
        event.preventDefault();

        try {
            const response = await axios.post('http://hawk.mini.io.vn/guest/login', { email:email, password:password });
            setShowPopup(false)
           
            if (response.data.jwt){
                toast({
                    title: 'Notification',
                    description:response.data.error,
                    status: 'success',
                    duration: 500,
                    isClosable: true,
                  })
                localStorage.setItem('jwt', response.data.jwt);
                Navigate("/")
            }else{
                toast({
                    title: 'Notification',
                    description:response.data.error,
                    status: 'warning',
                    duration: 1000,
                    isClosable: true,
                  })
            }
             
          } catch (error) {
            console.log(error)
            setShowPopup(false)
            toast({
                title: 'Notification',
                description: "ERROR.",
                status: 'error',
                duration: 1000,
                isClosable: true,
              })
          }
    };
    return (
        <div>

            <section1> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
                <span /> <span /> <span /> <span /> <span />
                <div className="signin">
                    <div className="content">
                        <h2>ADMIN TOOL</h2>
                        <div className="form">
                            <div className="inputBox">
                                <input type="text" id="username" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <i>Email</i>
                            </div>
                            <div className="inputBox">
                                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                <i>Password</i>
                            </div>
                            <div className="links"></div>
                            <div className="inputBox">
                           
                                <input id="loginButton" type="submit" value="Login" onClick={handleLogin} disabled={showPopup} />

                            </div>
                        </div>
                    </div>
                </div>
            </section1>
        </div>
    );
}
export default Login;