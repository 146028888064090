import React, { useState, useEffect } from 'react';

const Timer = ({ timestampInSeconds }) => {
  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      const elapsedTimeInSeconds = currentTimeInSeconds - timestampInSeconds;

      const hours = Math.floor(elapsedTimeInSeconds / 3600);
      const minutes = Math.floor((elapsedTimeInSeconds % 3600) / 60);
      const seconds = elapsedTimeInSeconds % 60;

      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      setElapsedTime(formattedTime);
    }, 1000); // Cập nhật mỗi giây

    return () => clearInterval(intervalId); // Hủy interval khi component bị unmount
  }, [timestampInSeconds]);

  return (
    <div>
      <h2>Play Time:</h2>
      <p>{elapsedTime}</p>
    </div>
  );
};

export default Timer;
