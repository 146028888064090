
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

function Logout() {
  const Navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('jwt')
    Navigate("/login")
  })
  return (
    <div className="App">
     Logout
    </div>
  );
}

export default Logout;
