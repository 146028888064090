import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Badge,
    IconButton,
    FocusLock,
    Stack,
    FormControl,
    ButtonGroup,
    useDisclosure,
    FormLabel,
    Input,
    Box,
    Select,
    NumberInput,
    Spinner
} from '@chakra-ui/react'

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'
import axios from 'axios';
import Timer from './timer'
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { PostReq } from "../lib/request";
import { realtimeDB } from '../lib/firebase';
import { getDatabase, ref, onValue, get, child } from "firebase/database";
import { EditIcon } from '@chakra-ui/icons'
let isGet = true
const TextInput = React.forwardRef((props, ref) => {
    return (
        <FormControl>
            <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
            <Input ref={ref} id={props.id} {...props} />
        </FormControl>
    )
})
const Error = ({ type, message }) => {
    return (<Alert status={type} position={"fixed"} bottom={"0px"}>
        <AlertIcon />
        {message}
    </Alert>)
}
const Gift = ({ setError, firstFieldRef, onCancel, user }) => {
    function getDefaultExpireDate() {
        const currentDate = new Date();
        const tomorrow = new Date(currentDate);
        tomorrow.setDate(currentDate.getDate() + 1); // Add one day
        return tomorrow.toISOString().slice(0, 16); // Return date string in "yyyy-MM-ddTHH:mm" format
    }

    const [click, setClick] = useState(false);
    const [giftData, setGiftData] = useState({
        id: 1,
        amount: 1,
        type: 'null',
        message: "System Gift",
        expire: getDefaultExpireDate()
    });

    const handleChange = (event) => {
        const { id, value } = event.target;
        setGiftData({ ...giftData, [id]: value });
    };

    const handleSendGift = () => {
        setClick(true);

        if (giftData.type === "null" || !giftData.type) {
            setError({ isShow: true, type: "error", message: "TypeGift is missing" })
            setClick(false);

        } else {
            let packet = giftData;
            packet.userid = user.id
            packet.expire = Date.parse(packet.expire) / 1000;
            if (!packet.expire) {
                setError({ isShow: true, type: "warning", message: "Expire is missing" })
                setClick(false);
                return
            }
            console.log(packet)
            PostReq("gift", packet)
                .then(() => {
                    setError({ isShow: true, type: "success", message: "Success" })
                    setClick(false);
                    onCancel()
                })
                .catch((error) => {
                    setError({ isShow: true, type: "error", message: error.message })
                    setClick(false);
                });
        }

    };

    return (
        <Stack spacing={4}>
            <h3>Send gift to {user.name}</h3>
            <Select id="type" placeholder='Please Select Gift Type' onChange={handleChange}>
                <option value='coin'>Coin</option>
                <option value='diamond'>Diamond</option>
                <option value='medal'>Medal</option>
                <option value='treasure'>Treasure</option>
                <option value='character'>Character</option>
                <option value='exp'>EXP</option>
            </Select>
            <TextInput label='ID Gift' id='id' defaultValue={giftData.id} onChange={handleChange} />
            <TextInput label='Amount' id='amount' defaultValue={giftData.amount} onChange={handleChange} />
            <TextInput label='Message' id='message' defaultValue={giftData.message} onChange={handleChange} />
            <Input
                id="expire"
                placeholder="Expires on"
                size="md"
                type="datetime-local"
                onChange={handleChange}
                defaultValue={giftData.expire}
            />
            <ButtonGroup display='flex' justifyContent='flex-end'>
                <Button variant='outline' onClick={onCancel}>
                    Cancel
                </Button>
                <Button colorScheme='teal' onClick={handleSendGift}>
                    {click ? (<Spinner color='red.500' />) : ("Send")}
                </Button>
            </ButtonGroup>

        </Stack>
    );
};
const GifForm = ({ user, setError }) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = React.useRef(null)

    return (
        <>
            <Box display='inline-block' mr={3}>
                Gift
            </Box>
            <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement='right'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <IconButton size='sm' icon={<EditIcon />} />
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <FocusLock returnFocus persistentFocus={false}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <Gift setError={setError} firstFieldRef={firstFieldRef} user={user} onCancel={onClose} />
                    </FocusLock>
                </PopoverContent>
            </Popover>
        </>
    )
}
function formatTimestamp(timestampInSeconds) {
    // Tạo một đối tượng Date từ timestamp (chú ý: timestamp là số giây)
    const date = new Date(timestampInSeconds * 1000);

    // Lấy các thành phần ngày, tháng, năm, giờ, phút, giây từ đối tượng Date
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Lưu ý: tháng bắt đầu từ 0
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Trả về chuỗi định dạng dd/mm/yyyy hh:mm:ss
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const UserItem = ({ user, setError }) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = React.useRef(null)
    return (
        <Tr key={user.id}>
            <Td>{user.isServer ? <Badge colorScheme='green'>Experience</Badge> : <Badge colorScheme='red'>Studio</Badge>}</Td>
            <Td>{user.isDebug ? "true" : "false"}</Td>
            <Td>{user.name}</Td>
            <Td>{user.placeId}</Td>
            <Td>{user.gameId}</Td>
            <Td>{user.ping ? user.ping : "NaN"}</Td>
            <Td><Popover>
                <PopoverTrigger>
                    <Button>{formatTimestamp(user.at)}</Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                        <Timer timestampInSeconds={user.at} />
                    </PopoverBody>
                </PopoverContent>
            </Popover></Td>
            <Td><Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement='right'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <IconButton size='sm' icon={<EditIcon />} />
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <FocusLock returnFocus persistentFocus={false}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                            {user.isServer ? (<Stack><GifForm key={user.id} user={user} setError={setError}></GifForm><Button onClick={() => {
                                PostReq("debug", { userid: user.id, turn_on: !user.isDebug })
                                    .then(() => {
                                        setError({ isShow: true, type: "success", message: "Success" })
                                        onClose()
                                    })
                                    .catch((error) => {
                                        setError({ isShow: true, type: "error", message: error.message })

                                    });
                            }} colorScheme='teal' variant='outline'>
                                DebugMode
                            </Button>
                            </Stack>) : (<Alert status='error'>
                                <AlertIcon />
                                <AlertTitle>Studio Player</AlertTitle>

                            </Alert>)}
                        </PopoverBody>
                    </FocusLock>
                </PopoverContent>
            </Popover></Td>
        </Tr>
    );
};
function Playing() {
    const [error, setError] = useState({
        isShow: false,
        type: "error",
        message: ""
    });
    useEffect(() => {
        if (error.isShow) {
            setTimeout(() => {
                setError({ ...error, ['isShow']: false })
            }, 1500);
        }
    }, [error])
    const [userList, setUserList] = useState({});
    useEffect(() => {

        const starCountRef = ref(realtimeDB, 'UserData');
        get(starCountRef, 'UserData').then((snapshot) => {
            let data = snapshot.val();
            if (data == null) {
                data = {}
            }

            setUserList(data)
            console.log(data)
        });

        onValue(starCountRef, (snapshot) => {
            let data = snapshot.val();
            if (data == null) {
                data = {}
            }
            if (data != userList) {
                setUserList(data)
            }
        });

    }, [])
    return (
        <div >
            <TableContainer>
                <Table>
                    <TableCaption>Roblox Realtime Player</TableCaption>
                    <Thead>
                        <Tr>

                            <Th>Version</Th>
                            <Th>Debug Mode</Th>
                            <Th>Name</Th>
                            <Th>PlaceId</Th>
                            <Th>gameId</Th>
                            <Th>Delay Network</Th>
                            <Th>Login At</Th>
                            <Th>Permission</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.values(userList).map((user, index) => (

                            <UserItem key={user.id} user={user} setError={setError} />



                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            {error.isShow ? (<Error type={error.type} message={error.message}></Error>) : (<></>)}
        </div>
    );
}

export default Playing;
